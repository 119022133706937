import React from "react"
import { Alert } from "antd"

const ErrorView = ({ error }) => {
  return (
    <Alert message="Error" description={error.message} type="error" showIcon />
  )
}

export default ErrorView
