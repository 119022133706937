import React, { useState } from "react"
import { Badge, Button, Col, Modal, Row } from "antd"
import { Link } from "gatsby"
import { useGlobalValue } from "../../hooks/useGlobalValue"
import { isSubscriptionValid } from "../../utils"
import { useTranslation } from "react-i18next"

const PremiumFeature = () => {
  const [{ userSubscription }] = useGlobalValue()
  const isPremium = isSubscriptionValid(userSubscription)
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()

  const premiumFeatures = [
    {
      title: t("myLibrary:statMembershipVideos"),
      link: "/landing",
      picture: "/assets/images/img-abbonamento-quad.webp",
    },
    {
      title: t("myLibrary:atlas3D"),
      link: "/landing",
      picture: "/assets/images/img-atlante-quad.webp",
    },
    {
      title: t("myLibrary:premiumProfileRegister"),
      link: "/landing",
      picture: "/assets/images/img-registro-quad.webp",
    },
    {
      title: t("myLibrary:couponAccess"),
      link: "/landing",
      picture: "/assets/images/img-bonus-quad.webp",
    },
  ]
  if (!isPremium) {
    return (
      <div>
        <Row gutter={[32, 16]}>
          <Col span={8} xs={24} sm={12} md={12} xl={8}>
            <div className="feature-card main-card">
              <img
                src="/assets/images/subscription-premium-white.svg"
                alt="subscription premium"
              />
              <p>{t("myLibrary:mainFeature")}</p>
              <Button
                type="primary"
                onClick={() => {
                  setShowModal(true)
                }}
              >
                {t("button:goPremium")}
              </Button>
              <Modal
                className="premium-feature-modal"
                visible={showModal}
                onCancel={() => {
                  setShowModal(false)
                }}
                footer={null}
              >
                <img
                  src="/assets/images/subscription-premium-light.svg"
                  alt="subscription premium"
                  className="logo"
                />
                <p className="subtitle">
                  {t("myLibrary:premiumFeatureModalTitle")}
                </p>
                <p>
                  <img
                    src="/assets/images/black-check.svg"
                    alt="subscription premium"
                  />
                  <span> {t("myLibrary:statTrainingHours")}</span>
                </p>
                <p>
                  <img
                    src="/assets/images/black-check.svg"
                    alt="subscription premium"
                  />
                  <span> {t("myLibrary:statProfessionalsCount")}</span>
                </p>
                <p>
                  <img
                    src="/assets/images/black-check.svg"
                    alt="subscription premium"
                  />
                  <span> {t("myLibrary:statCategories")}</span>
                </p>
                <p>
                  <img
                    src="/assets/images/black-check.svg"
                    alt="subscription premium"
                  />
                  <span> {t("myLibrary:humanAnatomyPlatform")}</span>
                </p>
                <p>
                  <img
                    src="/assets/images/black-check.svg"
                    alt="subscription premium"
                  />
                  <span> {t("myLibrary:ExamAccess")}</span>
                </p>
                <p>
                  <img
                    src="/assets/images/black-check.svg"
                    alt="subscription premium"
                  />
                  <span> {t("myLibrary:AppAccess")}</span>
                </p>
                <p>
                  <img
                    src="/assets/images/black-check.svg"
                    alt="subscription premium"
                  />
                  <span> {t("myLibrary:sessionAccess")}</span>
                </p>
                <Button type="primary">
                  <a href="/subscribe"> {t("button:accessNowPremium")}</a>
                </Button>
                <div className="text-center">
                  <Link to={"/landing"}>{t("button:landingLink")}</Link>
                </div>
              </Modal>
            </div>
          </Col>
          {premiumFeatures.map((feature, index) => (
            <Col key={index} span={4} xs={24} sm={12} md={12} xl={4}>
              <div
                className="feature-card"
                style={{ backgroundImage: `url(${feature.picture})` }}
              >
                <div className="feature-badge-wrapper">
                  <Badge className="subscription-page" count={"Premium"} />
                </div>
                <Row align="bottom">
                  <Col>
                    <h2>{feature.title}</h2>
                    <Link className="card-link" to={feature.link}>
                      {t("button:info")}
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    )
  } else {
    return (
      <div>
        <Row gutter={[32, 16]} align="bottom">
          <Col
            span={24}
            xs={24}
            sm={12}
            md={12}
            xl={12}
            className="premium-feature-card-wrapper"
          >
            <div
              className="feature-card main-card premium"
              style={{
                backgroundImage: `url(/assets/images/img-abbonamento-wide.webp)`,
              }}
            >
              <div className="membership-badge">
                <img
                  src="/assets/images/subscription-premium-white.svg"
                  alt="subscription premium"
                />
              </div>
              <div className="premium-info-wrapper">
                <Button type="primary">
                  <a href="/"> {t("myLibrary:accessUrVideos")}</a>
                </Button>
                <h2>{t("myLibrary:statMembershipVideos")}</h2>
              </div>
            </div>
          </Col>
          <Col
            span={24}
            xs={24}
            sm={12}
            md={12}
            xl={12}
            className="premium-feature-card-wrapper"
          >
            <div
              className="feature-card main-card premium"
              style={{
                backgroundImage: `url(/assets/images/img-atlante-wide.webp`,
              }}
            >
              <div className="membership-badge">
                <img
                  src="/assets/images/subscription-premium-white.svg"
                  alt="subscription premium"
                />
              </div>
              <div className="premium-info-wrapper">
                <Button type="primary">
                  <a href="/human">{t("button:accessNow")}</a>
                </Button>
                <h2>{t("myLibrary:atlas3D")}</h2>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default PremiumFeature
