import React, { useEffect } from "react"
import PageRoot from "../../components/PageRoot"
import { Col, Row } from "antd"
import { useTranslation } from "react-i18next"
import CertificationSummary from "../../components/library/certification-summary"
import RegistryProfileSummary from "../../components/library/registry-profile-summary"
import SettingBar from "../../components/library/setting-bar"
import PremiumFeature from "../../components/library/premium-feature"
import History from "../../components/library/history"
import Promo from "../../components/library/promo"
import UserCourses from "../../components/library/user-courses"
import UserCertifications from "../../components/library/user-certifications"
import CentralColumn from "../../components/library/library-layout"
import "../../components/library/library.less"
import UserExams from "../../components/library/user-exams"
import BonusContainer from "../../components/library/bonus-container"
import { SUBSCRIPTION_DISABLED } from "../../config"

const MyLibraryPage = () => {
  const { t } = useTranslation()
  const pageTitle = t("label:library")

  useEffect(() => {
    document.getElementById(window.location.hash)?.scrollIntoView()
  }, [])

  return (
    <PageRoot title={pageTitle} showTitle={false} layout="full">
      <div className="library-sections-wrapper">
        <section className="library-section">
          <CentralColumn>
            <div className="user-info-summary">
              <Row gutter={[16, 16]} align="center">
                <Col
                  span={12}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  className="info-count"
                >
                  <CertificationSummary />
                </Col>
                <Col
                  span={12}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  className="info-general"
                >
                  <RegistryProfileSummary />
                </Col>
              </Row>
            </div>
          </CentralColumn>
        </section>

        <section className="library-section">
          <CentralColumn>
            <div className="setting-bar-wrapper">
              <SettingBar />
            </div>
          </CentralColumn>
        </section>

        {!SUBSCRIPTION_DISABLED && (
          <section className="library-section">
            <CentralColumn>
              <div className="premium-feature-summary">
                <PremiumFeature />
              </div>
            </CentralColumn>
          </section>
        )}

        <section className="library-section" id="coupons">
          <BonusContainer />
        </section>

        <section className="library-section" id="history">
          <CentralColumn>
            <History />
          </CentralColumn>
        </section>

        <section className="library-section" id="exams">
          <CentralColumn>
            <UserExams />
          </CentralColumn>
        </section>

        <section className="library-section" id="promo">
          <CentralColumn>
            <Promo />
          </CentralColumn>
        </section>

        <section className="library-section" id="purchases">
          <CentralColumn>
            <UserCourses />
          </CentralColumn>
        </section>

        <section className="library-section">
          <CentralColumn>
            <UserCertifications />
          </CentralColumn>
        </section>
      </div>
    </PageRoot>
  )
}

export default MyLibraryPage
