import React, { useMemo } from "react"

import { renderCarouselItem } from "../../utils"
import AtsCarousel from "../AtsCarousel"
import { gql, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { Empty } from "antd"

const GET_USER_HISTORY_VIDEO = gql`
  {
    user_video_histories(
      order_by: { updated_at: desc_nulls_last }
      limit: 10
      distinct_on: updated_at
    ) {
      time
      video {
        __typename
        price_policy
        id
        state
        title
        subtitle
        level
        short_description
        duration
        seo {
          slug
        }
        course_rel {
          course_id
        }
        landscape {
          s3_key
        }
      }
    }
  }
`

const History = () => {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(GET_USER_HISTORY_VIDEO, {})

  const historyVideos = useMemo(() => {
    if (
      Array.isArray(data?.user_video_histories) &&
      data?.user_video_histories.length
    ) {
      return data.user_video_histories.map(item => {
        const { time, video } = item
        let progress = 0
        try {
          progress = (time / video.duration) * 100
        } catch (err) {
          console.log({ progressCalcError: err })
        }
        return {
          ...video,
          progress: progress,
        }
      })
    } else return null
  }, [data])

  if (historyVideos) {
    return (
      <AtsCarousel
        loading={loading}
        title={t("label:chronology")}
        url={"/"}
        learnMoreText={t("label:showSubscriptionVideos")}
        dataSource={historyVideos || []}
        renderItem={renderCarouselItem}
        emptyItem={<Empty description={t("message:noVideoWatched")} />}
      />
    )
  } else return null
}
export default History
