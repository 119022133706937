import React, { useMemo, useState } from "react"
import { Col, Empty, Row } from "antd"
import { gql, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import CouponPreview from "../../components/coupon"
import AtsCarousel from "../../components/AtsCarousel"
import LoadingIndicator from "../feedback/loading"
import { useAuth0 } from "@auth0/auth0-react"

const GET_USER_BONUS_QUERY = gql`
  query getCouponCode($now: timestamptz!, $userId: String!) {
    coupon_codes(
      where: {
        criterion: {
          _and: [
            {
              _or: [
                { start_at: { _lte: $now } }
                { start_at: { _is_null: true } }
              ]
            }
            { _or: [{ end_at: { _gt: $now } }, { end_at: { _is_null: true } }] }
          ]
        }
        consumed_at: { _is_null: true }
        user_id: { _eq: $userId }
      }
      order_by: { criterion: { end_at: asc_nulls_last } }
    ) {
      code
      user_id
      criterion {
        description
        discount_type
        end_at
        exclusive_for_content_id
        exclusive_for_content_type
        id
        percentage
        picture {
          s3_key
        }
        start_at
        title
        value
      }
    }
  }
`

const BonusContainer = () => {
  const { t } = useTranslation()
  const [now, setNow] = useState(new Date())
  const { user } = useAuth0()

  const { loading, error, data } = useQuery(GET_USER_BONUS_QUERY, {
    variables: {
      now,
      userId: user?.sub,
    },
  })

  const bonusCarousel = useMemo(() => {
    if (loading) {
      return <LoadingIndicator />
    }
    if (Array.isArray(data.coupon_codes) && data.coupon_codes.length) {
      return (
        <AtsCarousel
          title={" "}
          colSettings={{ xxl: 8 }}
          dataSource={data?.coupon_codes || []}
          emptyItem={<Empty description={t("label:noCouponsAvailable")} />}
          renderItem={(item, index) => {
            return <CouponPreview key={index} data={item} />
          }}
        />
      )
    } else {
      return null
    }
  }, [data, loading, error])

  if (bonusCarousel) {
    return (
      <Row justify="center" className="bg-yellow m-bottom m-top">
        <Col span={20} xs={20} xxl={16}>
          <Row gutter={[16, 16]}>
            <Col span={10} xs={24} xl={6} className="coupons-banner">
              <div className="coupons-banner-content">
                <h1 className="">Ti è stato assegnato un bonus!</h1>
                <p>
                  Utilizza adesso i tuoi Bonus prima che siano scaduti!
                  <br />
                  Le offerte Sport Science hanno una durata limitata, assicurati
                  di cogliere subito l'occasione!
                  <br />
                  <span className="bonus-text-separator">Oppure</span>
                  <br />
                  <a href="/gift-card" className="coupons-link">
                    Acquista una Gift Card da regalare ai tuoi amici
                  </a>
                </p>
              </div>
            </Col>
            <Col span={14} xs={24} xl={18} className="coupons-cards-wrapper">
              {bonusCarousel}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  } else {
    return null
  }
}

export default BonusContainer
