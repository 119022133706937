import React, { useMemo } from "react"
import { gql, useQuery } from "@apollo/client"
import AtsCarousel from "../AtsCarousel"
import { renderCarouselItem, translatePath } from "../../utils"
import { Empty } from "antd"
import { useTranslation } from "react-i18next"

const GET_USER_HISTORY_VIDEO = gql`
  fragment Image on files {
    is_public
    s3_key
  }
  fragment PreviewCardCourse on courses {
    __typename
    created_at
    id
    title
    subtitle
    level
    short_description
    price
    price_policy
    is_external
    external_url
    seo {
      slug
    }
    landscape {
      ...Image
    }
    portrait {
      ...Image
    }
  }
  {
    user_inventory_items(
      where: { course: { price_policy: { _eq: ON_DEMAND } } }
    ) {
      course {
        ...PreviewCardCourse
      }
    }
  }
`

const UserCourses = () => {
  const { t } = useTranslation()

  const { loading, error, data } = useQuery(GET_USER_HISTORY_VIDEO, {})

  const courseData = useMemo(() => {
    if (
      Array.isArray(data?.user_inventory_items) &&
      data?.user_inventory_items.length
    ) {
      return data.user_inventory_items.map(item => item.course)
    } else return null
  }, [data])
  if (courseData) {
    return (
      <div>
        <AtsCarousel
          url={translatePath("/on-demand")}
          loading={loading}
          title={t("label:myOnDemandCourses")}
          learnMoreText={t("label:purchaseNewOnDemandCourse")}
          dataSource={courseData}
          renderItem={renderCarouselItem}
          emptyItem={<Empty description={t("message:noOnDemandCourses")} />}
        />
      </div>
    )
  } else return null
}

export default UserCourses
