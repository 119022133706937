import React from "react"
import { Col, Row } from "antd"
import { translatePath } from "../../utils"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import {
  BellFilled,
  LogoutOutlined,
  QuestionCircleFilled,
  SettingFilled,
  ShoppingFilled,
  SnippetsFilled,
} from "@ant-design/icons"

const SettingBar = () => {
  const { t } = useTranslation()
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={4} xs={11} lg={4}>
          <Link to={translatePath("/me/profile")}>
            <SettingFilled /> {t("menu:settings")}
          </Link>
        </Col>
        <Col span={4} xs={11} lg={4}>
          <Link to={translatePath("/help")}>
            <QuestionCircleFilled /> {t("menu:helpDesk")}
          </Link>
        </Col>
        <Col span={4} xs={11} lg={4}>
          <Link to={translatePath("//me/billing/payment-method/list")}>
            <SnippetsFilled /> {t("menu:billing")}
          </Link>
        </Col>
        <Col span={4} xs={11} lg={4}>
          <Link to={translatePath("/me/billing")}>
            <ShoppingFilled /> {t("menu:billingHistory")}
          </Link>
        </Col>
        <Col span={4} xs={11} lg={4}>
          <Link to={translatePath("/me/subscription")}>
            <BellFilled /> {t("menu:subscription")}
          </Link>
        </Col>
        <Col span={4} xs={11} lg={4}>
          <Link to={translatePath("/auth/sign-out")}>
            <LogoutOutlined /> {t("menu:signOut")}
          </Link>
        </Col>
      </Row>
    </div>
  )
}

export default SettingBar
