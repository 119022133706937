import React, { useMemo } from "react"
import { Col, Empty, Row } from "antd"
import { gql, useQuery } from "@apollo/client"
import { renderCarouselItem } from "../../utils"
import AtsCarousel from "../AtsCarousel"
import { useTranslation } from "react-i18next"
import moment from "moment"
import withTileBadgeCheck from "../withTileBadgeCheck"
import BundleTile from "../BundleTile"
import { navigate } from "gatsby"

const GET_USER_BONUS_QUERY = gql`
  fragment SpecialPrice on special_prices {
    start_at
    end_at
    final_price
  }
  fragment Image on files {
    is_public
    s3_key
  }
  fragment PreviewCardAuthor on authors {
    __typename
    id
    firstname
    lastname
    title
    seo {
      slug
    }
    picture {
      ...Image
    }
    video_rels(
      where: {
        video: {
          _or: [{ state: { _eq: PUBLISHED } }, { state: { _eq: COMING_SOON } }]
        }
      }
    ) {
      video_id
    }
  }

  fragment PreviewCardVideo on videos {
    __typename
    created_at
    id
    state
    title
    subtitle
    level
    short_description
    price
    price_policy
    special_price {
      ...SpecialPrice
    }
    duration
    available_date
    seo {
      slug
    }
    landscape {
      ...Image
    }
    course_rel {
      course_id
      course {
        seo {
          slug
        }
      }
    }
    author_rels {
      author {
        ...PreviewCardAuthor
      }
    }
  }
  fragment PreviewCardCourse on courses {
    __typename
    created_at
    id
    title
    subtitle
    level
    short_description
    price
    price_policy
    special_price {
      ...SpecialPrice
    }
    is_external
    external_url
    seo {
      slug
    }
    landscape {
      ...Image
    }
    portrait {
      ...Image
    }
    videos(
      order_by: { index: asc }
      distinct_on: index
      where: {
        video: {
          _or: [{ state: { _eq: PUBLISHED } }, { state: { _eq: COMING_SOON } }]
        }
      }
    ) {
      video {
        state
        duration
      }
    }
  }
  fragment PreviewCardCertification on certifications {
    __typename
    id
    title
    subtitle
    state
    price
    special_price {
      ...SpecialPrice
    }
    short_description
    type
    seo {
      slug
    }
    landscape_locked {
      ...Image
    }
    landscape_success {
      ...Image
    }
    badge_title
    badge {
      ...Image
    }
    exam {
      coolDown
    }
  }
  fragment PreviewCardGiftCard on gift_cards {
    __typename
    id
    title
    subtitle
    short_description
    description
    price
    special_price {
      ...SpecialPrice
    }
    picture {
      ...Image
    }
    seo {
      slug
    }
    criterion {
      picture {
        ...Image
      }
      description
      discount_type
      end_at
      exclusive_for_content_id
      exclusive_for_content_type
      id
      percentage
      short_description
      start_at
      subtitle
      title
      value
    }
  }
  fragment PreviewCardBundle on bundles {
    __typename
    created_at
    id
    title
    subtitle
    short_description
    price
    seo {
      slug
    }
    landscape {
      ...Image
    }
  }
  {
    special_prices(order_by: { end_at: asc_nulls_last }) {
      final_price
      start_at
      end_at
      video {
        ...PreviewCardVideo
      }
      course {
        ...PreviewCardCourse
      }
      certification {
        ...PreviewCardCertification
      }
      gift_card {
        ...PreviewCardGiftCard
      }
      bundle {
        ...PreviewCardBundle
      }
    }
    bundles(
      order_by: { title: asc }
      where: {
        _and: [
          { state: { _eq: "PUBLISHED" } }
          { course_type: { _neq: "MASTER" } }
          { course_type: { _neq: "PATH" } }
        ]
      }
    ) {
      ...PreviewCardBundle
    }
  }
`

const Promo = () => {
  const { t } = useTranslation()

  const { loading, error, data } = useQuery(GET_USER_BONUS_QUERY, {})

  const specialPriceData = useMemo(() => {
    const now = new Date()
    if (!data) {
      return []
    }

    return data.special_prices
      .filter(item => !item.end_at || moment(item.end_at) > now)
      .map(item => {
        const { video, course, certification, gift_card, bundle } = item
        const result = {
          ...(video || course || certification || gift_card || bundle),
        }

        result.special_price = item
        return result
      })
  }, [data])

  const renderBundleItem = (item, index) => {
    const { seo } = item

    const TileComponent = withTileBadgeCheck(BundleTile)

    return (
      <TileComponent
        data={item}
        type="full"
        onClick={() => {
          navigate(`/bundle/${seo.slug}`)
        }}
      />
    )
  }

  return (
    <div>
      <Row gutter={[16, 16]}>
        {Boolean(specialPriceData.length) && (
          <Col span={12} xs={24} lg={24}>
            <AtsCarousel
              colSettings={{ xs: 20, lg: 8 }}
              url="/special-price"
              learnMoreText={t("label:showAll")}
              loading={loading}
              title={t("label:specialOffers")}
              dataSource={specialPriceData}
              renderItem={renderCarouselItem}
              emptyItem={<Empty description={t("message:noSpecialOffers")} />}
            />
          </Col>
        )}
        {Boolean((data?.bundles || []).length) && (
          <Col span={12} xs={24} lg={24}>
            <AtsCarousel
              colSettings={{ xs: 20, lg: 12 }}
              url="/bundle"
              learnMoreText={t("label:showAll")}
              loading={loading}
              title={t("label:bundleOffers")}
              dataSource={data?.bundles || []}
              renderItem={renderBundleItem}
              emptyItem={
                <Empty description={t("message:noBundlesAvailable")} />
              }
            />
          </Col>
        )}
      </Row>
    </div>
  )
}

export default Promo
