import React, { useMemo } from "react"
import { gql, useQuery } from "@apollo/client"
import AtsCarousel from "../AtsCarousel"
import { renderCarouselItem } from "../../utils"
import { Empty } from "antd"
import { useTranslation } from "react-i18next"

const GET_USER_HISTORY_VIDEO = gql`
  fragment PreviewCardCourse on certifications {
    __typename
    id
    title
    subtitle
    state
    price
    short_description
    type
    seo {
      slug
    }
    landscape_locked {
      is_public
      s3_key
    }
    landscape_success {
      is_public
      s3_key
    }
    badge_title
    badge {
      is_public
      s3_key
    }
    exam {
      coolDown
    }
  }
  {
    user_inventory_items {
      certification {
        ...PreviewCardCourse
      }
    }
  }
`

const UserExams = () => {
  const { t } = useTranslation()

  const { loading, error, data } = useQuery(GET_USER_HISTORY_VIDEO, {})

  const examsData = useMemo(() => {
    if (!data) {
      return []
    }

    if (
      Array.isArray(data.user_inventory_items) &&
      data.user_inventory_items.length
    ) {
      return data.user_inventory_items.map(item => item.certification)
    } else {
      return null
    }
  }, [data])

  if (examsData) {
    return (
      <div>
        <AtsCarousel
          loading={loading}
          title={t("label:myAllExams")}
          dataSource={examsData}
          renderItem={renderCarouselItem}
          emptyItem={<Empty description={t("message:noExams")} />}
        />
      </div>
    )
  } else {
    return null
  }
}

export default UserExams
