import React, { useEffect, useMemo, useState } from "react"
import "./CouponPreview.less"
import { Button, Card, Col, Row } from "antd"
import {
  devLog,
  getCurrencySymbol,
  getImageUrl,
  translatePath,
} from "../../utils"
import { PERCENTAGE } from "../../enums/DiscountTypes"
import { useTranslation } from "react-i18next"
import Countdown from "../Countdown"
import moment from "moment"
import { Link } from "gatsby"
import {
  BUNDLE,
  CERTIFICATION,
  GIFT_CARD,
  SUBSCRIPTION,
} from "../../enums/ItemTypes"
import LazyWrapper from "../LazyWrapper"

const CouponPreview = ({ data }) => {
  const { t } = useTranslation()

  const {
    title,
    short_description,
    discount_type,
    value,
    percentage,
    start_at,
    end_at,
    exclusive_for_content_id,
    exclusive_for_content_type,
  } = data.criterion

  const [countdownDuration, setCountdownDuration] = useState(null)

  const couponLink = useMemo(() => {
    if (exclusive_for_content_id) {
      return `/go?id=${exclusive_for_content_id}`
    }

    switch (exclusive_for_content_type) {
      case CERTIFICATION:
        return translatePath("/certification/all")
      case GIFT_CARD:
        return "/gift-card"
      case BUNDLE:
        return "/bundle"
      case SUBSCRIPTION:
        return `/auth/sign-up?coupon=${data.code}`
      default:
        return `/on-demand`
    }
  }, [data])

  useEffect(() => {
    if (!end_at) {
      setCountdownDuration(null)
      return
    }

    const now = moment()
    setCountdownDuration(moment(end_at) - now)
  }, [data, end_at])

  devLog({ countdownDuration, data })

  const couponImageUrl = getImageUrl(data.criterion.picture)

  let discountValue, discountSuffix
  if (discount_type === PERCENTAGE) {
    discountValue = (percentage * 100).toFixed(0)
    discountSuffix = "%"
  } else {
    discountValue = value
    discountSuffix = getCurrencySymbol(process.env.GATSBY_CURRENCY)
  }

  return (
    <LazyWrapper>
      <Card
        style={{ borderRadius: 25, overflow: "hidden" }}
        className="coupon-preview-card"
        cover={<img alt={title} src={couponImageUrl} />}
        hoverable={true}
      >
        <Card.Meta title={title} description={short_description} />
        <br />
        <Row justify="space-between" gutter={[16, 16]}>
          <Col>
            <h3>
              <span>{t("label:discount")} </span>
              <span className="coupon-discount-value">{`${discountValue} ${discountSuffix}`}</span>
            </h3>
          </Col>
          <Col>
            {countdownDuration && (
              <>
                <span>{t("label:expiresIn")} </span>
                <Countdown
                  duration={countdownDuration}
                  onExpire={() => {
                    window.location.reload()
                  }}
                />
              </>
            )}
          </Col>
          {couponLink && (
            <Col span={24}>
              <Button
                type="primary"
                className="yellowsp"
                style={{ width: "100%" }}
              >
                <Link to={couponLink}>{t("button:startShopping")}</Link>
              </Button>
            </Col>
          )}
        </Row>
      </Card>
    </LazyWrapper>
  )
}

export default CouponPreview
