import React, { useEffect, useMemo, useState } from "react"
import { Avatar, Badge, Button, Col, Row } from "antd"
import { useTranslation } from "react-i18next"
import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"
import { UserOutlined } from "@ant-design/icons"
import { useGlobalValue } from "../../hooks/useGlobalValue"
import { getUserDisplayName, isSubscriptionValid } from "../../utils"
import ErrorView from "../feedback/error-view"
import LoadingIndicator from "../feedback/loading"

const RegistryProfileSummary = () => {
  const { user: auth0User } = useAuth0()
  const [{ user, isLoadingUser, userSubscription }] = useGlobalValue()
  const [subscriptionType, setSubscriptionType] = useState("FREE")

  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [registryProfile, setRegistryProfile] = useState(null)

  const loadProfile = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${
          process.env.GATSBY_REGISTRY_ENDPOINT ||
          "https://38f8-165-154-225-47.ap.ngrok.io"
        }/api/user/${window.btoa(auth0User.sub)}/trainer`
      )

      setRegistryProfile(response.data?.trainer)
    } catch (e) {
      setError(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (auth0User) {
      loadProfile()
    }
  }, [auth0User])

  const profileName = useMemo(() => {
    if (registryProfile) {
      return registryProfile.name
    }

    if (user) {
      return getUserDisplayName(user)
    }

    if (auth0User) {
      return getUserDisplayName(auth0User)
    }

    return "..."
  }, [user, auth0User, registryProfile])

  useEffect(() => {
    if (userSubscription && isSubscriptionValid(userSubscription)) {
      setSubscriptionType("PREMIUM")
    }
  }, [userSubscription])

  if (loading) {
    return <LoadingIndicator />
  }

  if (error) {
    return <ErrorView error={error} />
  }

  return (
    <div className="h-100">
      <Row gutter={[16, 16]} className="h-100">
        <Col className="avatar-wrapper">
          {registryProfile?.picture_url ? (
            <Avatar size={128} src={registryProfile.picture_url} />
          ) : (
            <Avatar size={128} icon={<UserOutlined />} />
          )}
        </Col>
        <Col className="general-info-wrapper">
          <div>
            <p className="profile-info-header">
              <span className="profile-name">{profileName} </span>
              <Badge
                className="subscription-page"
                count={subscriptionType}
                style={{
                  backgroundColor:
                    subscriptionType === "FREE" ? "#5ac05a" : "#EC9A0C",
                }}
              />
            </p>
            <span className="profile-status">
              {!registryProfile && (
                <p className="profile-status-warning">
                  {t("warning:noPublicProfile")}
                </p>
              )}
            </span>
            <a
              href={"https://register.sportscience.com/registration"}
              target="_blank"
            >
              <Button type="primary" className="yellowsp">
                {registryProfile
                  ? t("button:updateRegistryProfile")
                  : t("button:createRegistryProfile")}
              </Button>
            </a>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default RegistryProfileSummary
