import React from "react"
import { Col, Row } from "antd"

const CentralColumn = ({ children }) => {
  return (
    <Row justify="center">
      <Col span={20} xs={20} xxl={16}>
        {children}
      </Col>
    </Row>
  )
}

export default CentralColumn
