import React, { useEffect, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { Col, Progress, Row } from "antd"
import { useTranslation } from "react-i18next"
import LoadingIndicator from "../feedback/loading"
import ErrorView from "../feedback/error-view"

const GET_CERTIFICATION_QUERY = gql`
  {
    certifications {
      type
    }
    user_inventory_items(where: { item_type: { _eq: "CERTIFICATION" } }) {
      certification {
        type
      }
    }
  }
`

const CertificationSummary = () => {
  const { t } = useTranslation()

  const labels = [
    t("label:certifications"),
    t("label:diplomas"),
    t("label:masters"),
  ]

  const { loading, error, data } = useQuery(GET_CERTIFICATION_QUERY, {})

  const [totalCertificationCounts, setTotalCertificationCounts] = useState([
    0, 0, 0,
  ])
  const [userCertificationCounts, setUserCertificationCounts] = useState([
    0, 0, 0,
  ])

  useEffect(() => {
    if (data) {
      let totalCertificationCount = 0
      let totalDiplomaCount = 0
      let totalMasterCount = 0

      let userCertificationCount = 0
      let userDiplomaCount = 0
      let userMasterCount = 0

      data.certifications.forEach(cert => {
        switch (cert.type) {
          case "CERTIFICATION":
            totalCertificationCount++
            break
          case "DIPLOMA":
            totalDiplomaCount++
            break
          case "MASTER":
            totalMasterCount++
            break
          default:
            break
        }
      })

      data.user_inventory_items.forEach(item => {
        switch (item?.certification.type) {
          case "CERTIFICATION":
            userCertificationCount++
            break
          case "DIPLOMA":
            userDiplomaCount++
            break
          case "MASTER":
            userMasterCount++
            break
          default:
            break
        }
      })

      setTotalCertificationCounts([
        totalCertificationCount,
        totalDiplomaCount,
        totalMasterCount,
      ])
      setUserCertificationCounts([
        userCertificationCount,
        userDiplomaCount,
        userMasterCount,
      ])
    }
  }, [data])

  if (loading) {
    return <LoadingIndicator />
  }

  if (error) {
    return <ErrorView error={error} />
  }

  return (
    <div>
      <Row
        gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}
        justify="center"
        align="center"
        className={"certification-summary-wrapper"}
      >
        {totalCertificationCounts.map((count, index) => (
          <Col key={index} span={8}>
            <p className="certification-count">
              <span className="text-white">
                {userCertificationCounts[index]}
              </span>
              <span>/</span>
              <span>{totalCertificationCounts[index]}</span>
            </p>
            <Progress
              percent={
                (userCertificationCounts[index] /
                  (totalCertificationCounts[index] || 1)) *
                100
              }
              showInfo={false}
            />
            <p className="certification-category">{labels[index]}</p>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default CertificationSummary
